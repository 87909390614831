import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"

import facebook from '../../img/social/facebook.svg'
import instagram from '../../img/social/instagram.svg'
import twitter from '../../img/social/twitter.svg'

const pageMetadata  = {
  title: 'Antiviral Foods and Drinks',
  description: 'A database and list of whole foods, such as vegetables, fruits, teas, nuts, herbs, and spices, as well as drinks that have antiviral activities agains viruses.'
}

export default () => (
  <Layout pageMetadata = {pageMetadata}>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">

          <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          Antiviral Foods & Drinks
          </h2>
          <p>Below is the list of antiviral foods, drinks, and references. The list is best viewed on a desktop browser at this <a href="https://airtable.com/shrEUzjjM0ZTjafZ7/tblsJtu6Vv7XUEWE4?backgroundColor=blue&viewControls=on">link</a>.<br></br>If you like to help with researching foods and drinks to fight COVID-19, please contact us.</p>



          <div className="columns" style={{marginTop:"20px"}}>

          <div style={{textAlign: 'center', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-3x ef-button-report-effectivenessx">
          <Link className="btn" to="/products" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#800000", color: "white"}}>
            Subscribe
          </Link>
          </div>


          <div class="column is-3x" style={{padding: "8px", backgroundColorx: "#ff0000"}}>
                          <div className="content has-text-centered ef-social-top-sectionx">

                          <div className="column is-4x social ef-social-top-sectionx has-text-centered">
                            <a title="facebook" href="https://facebook.com" style={{backgroundColor: '#00693eff'}}>
                              <img
                                src={facebook}
                                alt="Facebook"
                                style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                              />
                            </a>
                            <a title="twitter" href="https://twitter.com" style={{backgroundColor: '#00693eff'}}>
                              <img
                                className="fas fa-lg"
                                src={twitter}
                                alt="Twitter"
                                style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                              />
                            </a>
                            <a title="instagram" href="https://instagram.com" style={{backgroundColor: '#00693eff'}}>
                              <img
                                src={instagram}
                                alt="Instagram"
                                style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                              />
                            </a>
                          </div>
                          </div>
        </div>


          <div style={{textAlign: 'center', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-3x ef-button-report-effectivenessx">
          <Link className="btn" to="/products" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#800000", color: "white"}}>
            Volunteer
          </Link>
          </div>




            </div>



          <div align="center">
          <Helmet>
            <script src={withPrefix('subscribe-jotform.js')} type="text/javascript" />
          </Helmet>




          </div>




          <div align="center">
          <iframe title="Antiviral Foods" class="airtable-embed" src="https://airtable.com/embed/shrEUzjjM0ZTjafZ7?backgroundColor=blue&viewControls=on" frameborder="0" onmousewheel="" width="1000" height="600" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>


          </div>
          </div>

        </div>

      </div>
    </div>
</section>
  </Layout>
)
